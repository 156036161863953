import { EmojiSadIcon } from '@heroicons/react/outline';
import ButtonLink from '@components/ButtonLink';
import { ReactNode } from 'react';
import cn from 'classnames';

type CTAConfig = { href: string; label: ReactNode };
const defaultCTA: CTAConfig = { href: '/', label: 'Go home' };

type Props = { cta?: CTAConfig; message?: ReactNode; icon?: ReactNode; className?: string; isIframe?: boolean };
const PageNotFoundView = ({
    cta = defaultCTA,
    message = (
        <>
            You don&apos;t have permission to view the page you&apos;re looking for
            <br />
            or it may not exist.
        </>
    ),
    icon = <EmojiSadIcon height={24} width={24} />,
    className,
    isIframe = false,
}: Props) => {
    return (
        <div
            className={cn('flex flex-grow flex-col items-center justify-center pb-24 text-center lg:pb-32', className)}
        >
            <div className="mx-auto mb-1 inline-block rounded-full bg-indigo-100 p-4 text-indigo-800">{icon}</div>
            <h2 className="text-2xl font-semibold tracking-tight">Whoops!</h2>
            <p className="mb-4 whitespace-pre-line px-4 text-base">{message}</p>
            {cta && !isIframe ? (
                <ButtonLink color="primary" variant="contained" href={cta.href}>
                    {cta.label}
                </ButtonLink>
            ) : null}
        </div>
    );
};

export default PageNotFoundView;
