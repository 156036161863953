import Button from '@components/Button';
import { useState } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import cn from 'classnames';
import Link from 'next/link';
import MobileSidebar from '@components/MobileSidebar';
import { MenuIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import useAuth from '@hooks/useAuth';

const NavItems = () => {
    const router = useRouter();
    const { isLoggedIn } = useAuth();
    const isDark = router.pathname.includes('get-info');

    const goToLabspace = () => {
        if (!!isLoggedIn) return (window.location.href = '/labspace');
        router.replace('/login');
    };

    return (
        <>
            <span className="flex items-center justify-center">
                <Link href="https://pluto.bio/get-info" legacyBehavior>
                    <Button
                        variant="contained"
                        color={isDark ? 'secondary' : 'primary'}
                        data-cy="getstarted-btn"
                        size="small"
                        textClass="tracking-tight"
                    >
                        Get started
                    </Button>
                </Link>
                <Button
                    variant="outlined"
                    color={isDark ? 'white' : 'primary'}
                    size="small"
                    loading={false}
                    data-cy="labspace-btn"
                    onClick={goToLabspace}
                    className="ml-2"
                >
                    Lab Space
                </Button>
            </span>
        </>
    );
};

export type HeaderProps = {
    showMenu?: boolean;
    sticky?: boolean;
    dark?: boolean;
};

export const NavBar = ({ showMenu = true, sticky = true }: HeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const small = useMediaQuery('(min-width:640px) and (max-width:767px)');

    return (
        <>
            <div id="top" />
            <header
                className={cn('relative top-0 z-30 px-5 py-2 backdrop-blur-lg transition sm:p-6 md:py-4 bg-white', {
                    'sticky bg-opacity-60': sticky,
                })}
            >
                <div className="mx-auto flex max-w-screen-2xl items-center justify-between">
                    <Link
                        href="https://pluto.bio"
                        passHref
                        legacyBehavior
                        className="cursor-pointer text-primary hover:opacity-70"
                        aria-label="Pluto home page"
                    >
                        <img
                            src="https://cdn.bfldr.com/9AK17BV1/at/wkvwmg2jt85z4xg6f52wnw/Pluto_Logo?width=180"
                            alt="Pluto Bio"
                            className="cursor-pointer"
                        />
                    </Link>
                    {showMenu && !small && (
                        <IconButton
                            sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                            size="small"
                            onClick={() => setMenuOpen(true)}
                            aria-label={menuOpen ? 'Close navigation menu' : 'Open navigation menu'}
                        >
                            <span className="relative z-20 text-indigo-500 sm:hidden">
                                <MenuIcon width={24} className="text-primary dark:text-white" />
                            </span>
                        </IconButton>
                    )}
                    <nav
                        className={cn(
                            'absolute bottom-0 left-0 right-0 top-0 z-10 hidden h-screen w-full flex-col bg-indigo-600 p-6 pb-24 text-base tracking-tight sm:static sm:flex sm:h-auto sm:w-auto sm:flex-row sm:bg-transparent sm:p-0',
                        )}
                    >
                        <NavItems />
                    </nav>
                    <MobileSidebar
                        open={menuOpen}
                        setOpen={setMenuOpen}
                        noPadding
                        className="bg-indigo-600 px-8 pb-28"
                        direction="right"
                    >
                        <div className="flex flex-col">
                            <NavItems />
                        </div>
                    </MobileSidebar>
                </div>
            </header>
        </>
    );
};

export default NavBar;
