import NavBar from '@/src/components/NavBar';
import { PageLayout } from '@layouts/Layout';
import PageNotFoundView from '@components/errors/PageNotFoundView';

const ErrorPage: PageLayout = () => {
    return (
        <div className="flex h-screen w-full flex-col">
            <NavBar />
            <PageNotFoundView />
        </div>
    );
};
export default ErrorPage;
